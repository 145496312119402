
import {computed, defineComponent, onMounted, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import * as Yup from "yup";
import infoList from "@/core/data/accountworktypeoption";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface IFormVals {
    Title: string;
    Type: string;
    Country: string;
    City: string;
    Language: string;
    Web: string;
    Phone: string;
    Email: string;
    OperationEmail: string;
    OperationPhone: string;
    AccountingEmail: string;
    AccountingPhone: string;
    SaleEmail: string;
    SalePhone: string;
    LoginName: string;
    WorkType: string;
    WorkTypeOptions: string;
    Address: string;
    CompanyName: string;
    TaxOffice: string;
    TaxNumber: string;

}

export default defineComponent({
    components: {
        Field, Form, ErrorMessage,
    },
    setup() {

        const formVals = ref<IFormVals>({
            Title: "",
            Type: "",
            Country: "",
            City: "",
            Language: "",
            Web: "",
            Phone: "",
            Email: "",
            OperationEmail: "",
            OperationPhone: "",
            AccountingEmail: "",
            AccountingPhone: "",
            SaleEmail: "",
            SalePhone: "",
            LoginName: "",
            WorkType: "",
            WorkTypeOptions: "",
            Address: "",
            CompanyName: "",
            TaxOffice: "",
            TaxNumber: "",
        });

        const submitButtonRef = ref<null | HTMLButtonElement>(null);

        onMounted(()=>{
            store.dispatch(Actions.CITY_LIST, {});
        });

        const lstCities = computed(() => {
            return store.getters.CityList.data;
        });

        function filteredCities () {
            if (lstCities.value!==undefined) {
                return (lstCities.value).filter(citiesItem =>
                    (citiesItem.Country.toLowerCase()) == formVals.value.Country.toLowerCase()
                );
            }
            return [];
        }

        const filteredWorkTypeInfo = computed(() => {
            return infoList[formVals.value.WorkType];
        });

        const validationSchema = Yup.object().shape({
            Title: Yup.string().required("Hesap Adı girilmemiş"),
            Type: Yup.string().required("Hesap Tipi seçilmemiş"),
            Country:Yup.string().required("Ülke seçilmemiş"),
            City:Yup.string().required("Şehir seçilmemiş"),
            Language:Yup.string().required("Dil seçilmemiş"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const vvPayload = {
                Title: formVals.value.Title,
                Type: formVals.value.Type,
                Country: formVals.value.Country,
                City: formVals.value.City,
                Language: formVals.value.Language,
                Web: formVals.value.Web,
                Phone: formVals.value.Phone,
                Email: formVals.value.Email,
                OperationPhone: formVals.value.OperationPhone,
                OperationEmail: formVals.value.OperationEmail,
                AccountingPhone: formVals.value.AccountingPhone,
                AccountingEmail: formVals.value.AccountingEmail,
                SalePhone: formVals.value.SalePhone,
                SaleEmail: formVals.value.SaleEmail,
                LoginName: formVals.value.LoginName,
                WorkType: formVals.value.WorkType,
                WorkTypeOptions: formVals.value.WorkTypeOptions,
                Address: formVals.value.Address,
                CompanyName: formVals.value.CompanyName,
                TaxOffice: formVals.value.TaxOffice,
                TaxNumber: formVals.value.TaxNumber,
                Tags: "",
                CompanyID: "0",
            }

            console.log("submit v2 "+JSON.stringify(vvPayload));

            store.dispatch(Actions.ACCOUNT_INSERT, vvPayload).then(() => {
                Swal.fire({
                    text: "Tebrikler. Başarılı bir şekilde kayıt işlemini tamamladınız.", confirmButtonText: "Tamam!",
                    icon: "success", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                });
            }).catch(() => {
                Swal.fire({
                    text: store.getters.getUserErrors[0], confirmButtonText: "Tekrar dene!",
                    icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                });
            });
        };

        return {
            submit, submitButtonRef, validationSchema,
            formVals,
            lstCities, filteredCities, filteredWorkTypeInfo,
        };
    },
});
